import leftArrow from "@/assets/img/left-arrow.svg";
import rightArrow from "@/assets/img/right-arrow.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import classes from "./_suggestions.module.scss";
import "swiper/css";
import classNames from "classnames";

const Suggestions: React.FC = () => {
    return (
        <section className={classes.suggestions}>
            <div className={classes.container}>
                <h3 className={classes.suggestions__title}>
                    Что мы предлагаем
                </h3>
                <div className={classes.suggestions__items}>
                    <img
                        className={classNames(
                            classes.suggestions__itemsImg,
                            "swiper-button-prev"
                        )}
                        src={leftArrow}
                        alt='leftArrow'
                    />
                    <Swiper
                        modules={[Navigation]}
                        spaceBetween={50}
                        slidesPerView={1}
                        navigation={{
                            nextEl: ".swiper-button-next",
                            prevEl: ".swiper-button-prev",
                        }}
                        breakpoints={{
                            380: {
                                slidesPerView: 3,
                                spaceBetween: 5,
                            },
                        }}
                        className='suggestions__swiper'
                    >
                        <SwiperSlide>
                            <div className={classes.suggestions__item}>
                                <p className={classes.suggestions__itemText}>
                                    Простые шаблоны разных форматов
                                </p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={classes.suggestions__item}>
                                <p className={classes.suggestions__itemText}>
                                    Возможность добавлять дополнительные
                                    материалы
                                </p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={classes.suggestions__item}>
                                <p className={classes.suggestions__itemText}>
                                    Управление командой преподавателей
                                </p>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                    <img
                        className={classNames(
                            classes.suggestions__itemsImg,
                            "swiper-button-next"
                        )}
                        src={rightArrow}
                        alt='rightArrow'
                    />
                </div>
            </div>
        </section>
    );
};

export default Suggestions;
