import classNames from "classnames";
import classes from "./_footer.module.scss";

const Footer: React.FC = () => {
    return (
        <footer className={classes.footer}>
            <div
                className={classNames(
                    classes.container,
                    classes.footer__wrapper
                )}
            >
                <div className={classes.footer__leftside}>
                    <p className={classes.footer__leftsideText}>
                        ООО «Академия Лад» <br />  603093, г. Нижний Новгород,
                        ул. Родионова, д. 23В ИНН 5260489093, ОГРН 1235200007448
                        Образовательная лицензия № Л035-01281-52/00652634
                    </p>
                </div>
                <div className={classes.footer__rightside}>
                    <div className={classes.footer__rightsideLeft}>
                        <p className={classes.footer__rightsideLeftText}>
                            Сервис для запуска онлайн-курсов <br /> Платформа{" "}
                            <br />
                            2024
                        </p>
                        <a href='mailto:lad-academy@lad24.ru'>
                            lad-academy@lad24.ru
                        </a>
                        <a href='tel:+79092859770'>+7(909)285-97-70</a>
                    </div>
                    <div className={classes.footer__rightsideRight}>
                        <a href='#s'>
                            <button className={classes.footer__contactBtn}>
                                Связяться
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
