import classNames from "classnames";
import laptop from "@/assets/img/laptop.webp";
import green from "@/assets/img/green.webp";
import line from "@/assets/img/dot-line.svg";
import classes from "./HowItWorks/_how-it-works.module.scss";
import t_classes from "@/styles/blocks/_trial.module.scss";
import HowItWorks from "./HowItWorks/HowItWorks";
import Suggestions from "./Suggestions/Suggestions";
import "swiper/css";

const MainContent: React.FC = () => {
    return (
        <main>
            <section className={classes.platform}>
                <div
                    className={classNames(
                        classes.container,
                        classes.platform__wrapper
                    )}
                >
                    <div className={classes.platform__texts}>
                        <h1
                            className={classNames(
                                classes.platform__title,
                                classes.green
                            )}
                        >
                            ПЛАТФОРМА
                        </h1>
                        <h2 className={classes.platform__text}>
                            сервис для быстрого <br />
                            <span
                                className={classNames(
                                    classes.platform__text,
                                    classes.green
                                )}
                            >
                                создания онлайн-курсов
                            </span>
                        </h2>
                    </div>
                    <div className={classes.platform__images}>
                        <img
                            className={classes.platform__imagesLaptop}
                            src={laptop}
                            alt='laptop'
                        />
                        <img
                            className={classes.platform__imagesGreen}
                            src={green}
                            alt='green'
                        />
                    </div>
                </div>
                <div className={classes.platform__dotLine}>
                    <img
                        className={classes.platform__imagesLine}
                        src={line}
                        alt='dotline'
                    />
                </div>
            </section>
            <Suggestions />
            <section className={classes.how__it__works}>
                <div className={classes.container}>
                    <h2 className={classes.how__it__worksTitle}>
                        Как это работает
                    </h2>
                    <HowItWorks />
                </div>
            </section>
            <section className={classes.trial}>
                <div className={classes.container}>
                    <h3 className={t_classes.trial__title}>
                        Попробуйте Платформу <br />
                        бесплатно
                    </h3>
                    <div className={classes.trial__loginReg}>
                        <a href='#s'>
                            <button className={classes.loginReg__btn}>
                                Зарегистрироваться
                            </button>
                        </a>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default MainContent;
