import { useState } from "react";
import classes from "./_how-it-works.module.scss";
import laptop2 from "@/assets/img/laptop2.webp";
import downArrow from "@/assets/img/down-arrow.svg";
import classNames from "classnames";

interface FeatureBlock {
    title: string;
    text: string;
}

const blocks: FeatureBlock[] = [
    {
        title: "Подстройте страницу под себя и своих студентов",
        text: "У вас будет список разных шаблонов, из которых вы можете выбрать нужный под свои цели. Можно удалить и добавлять элементы страницы, а также менять их расположение и цвет.",
    },
    {
        title: "Сделайте курс интерактивным",
        text: "У вас будет список разных шаблонов, из которых вы можете выбрать нужный под свои цели. Можно удалить и добавлять элементы страницы, а также менять их расположение и цвет.",
    },
    {
        title: "Управляйте командой преподавателей",
        text: "Администратор на Платформе может контролировать весь процесс обучения. У него есть возможность добавлять в команду преподавателей, а они сами будут создавать курсы и уроки. При этом у Администратора тоже будет доступ к ним: он управляет всем опубликованным контентом.",
    },
];

const HowItWorks: React.FC = () => {
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const handleToggle = (index: number) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div className={classes.how__it__worksWrapper}>
            <div className={classes.how__it__worksImage}>
                <img src={laptop2} alt='laptop2' />
            </div>
            <div className={classes.how__it__worksBlocks}>
                {blocks.map((block, index) => (
                    <div className={classes.how__it__worksBlock} key={index}>
                        <div className={classes.how__it__worksBlockWrapper}>
                            <h3 className={classes.how__it__worksBlockTitle}>
                                {block.title}
                            </h3>
                            <img
                                onClick={() => handleToggle(index)}
                                src={downArrow}
                                alt='downArrow'
                                className={classes.how__it__worksBlockImg}
                            />
                        </div>

                        <p
                            className={classNames(
                                classes.how__it__worksBlockText,
                                activeIndex !== index ? classes.collapsed : ""
                            )}
                        >
                            {block.text}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default HowItWorks;
