import classNames from "classnames";
import classes from "./_header.module.scss";

const Header: React.FC = () => {
    return (
        <header className={classes.header}>
            <div
                className={classNames(
                    classes.container,
                    classes.header__wrapper
                )}
            >
                <div className={classes.header__text}>
                    платф<span className={classes.header__textGreen}>о</span>рма
                </div>
                <div className={classes.header__login}>
                    <div className={classes.header__loginLog}>
                        <a href='#s'>
                            <button className={classes.loginLog__btn}>
                                Войти
                            </button>
                        </a>
                    </div>
                    <div className={classes.header__loginReg}>
                        <a href='#s'>
                            <button className={classes.loginReg__btn}>
                                Зарегистрироваться
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
